import React from 'react';


const DAOs = () => {
  return (
    <div style={styles.container}>
      <h2 style={styles.header}>DAOs</h2>
      <p style={styles.paragraph}>
        At <strong>Pillarwheel.io</strong>, we're redefining the gaming experience through our innovative <strong>Decentralized Autonomous Organization (DAO)</strong> governance model. This fully decentralized protocol empowers players to take an active role in shaping the future of our games and the project's tokenomics.
      </p>

      <div style={styles.section}>
        <h3 style={styles.subHeader}>A New Era of Decentralized Gaming</h3>
        <p style={styles.paragraph}>
          Pillarwheel.io is committed to creating a series of fully decentralized games that operate autonomously. By merging the principles of Decentralized Finance (DeFi) with gaming, we offer a unique platform where players can influence development and enjoy a dynamic economy.
        </p>
      </div>

      <div style={styles.section}>
        <h3 style={styles.subHeader}>Exploring the Potential of DeFi in Gaming</h3>
        <p style={styles.paragraph}>
          Leveraging the transformative power of DeFi, our games create an interactive ecosystem that offers both entertainment and financial opportunities. Players engage in a decentralized world where they can invest in digital assets and participate in the platform’s growth, contributing to the broader adoption of DeFi in gaming.
        </p>
      </div>

      <div style={styles.section}>
        <h3 style={styles.subHeader}>Game Objects as NFTs</h3>
        <p style={styles.paragraph}>
          In the Pillarwheel ecosystem, most game objects are represented as <strong>non-fungible tokens (NFTs)</strong>. These unique digital assets can be owned, traded, and sold on the blockchain, providing players with verifiable ownership and enhancing the value of their in-game items. Our NFT integration brings real-world value to your gameplay, turning in-game achievements into tangible assets.
        </p>
      </div>

      <div style={styles.section}>
        <h3 style={styles.subHeader}>Interoperability and Cross-Game Use</h3>
        <p style={styles.paragraph}>
          We go beyond traditional NFT use by enabling interoperability across various games in the Pillarwheel ecosystem. Players can utilize their NFT collections across different titles and genres, extending the utility and value of their assets beyond a single game. This fosters a continuous and rewarding experience, enhancing the connection between players and their digital collections.
        </p>
      </div>

      <div style={styles.section}>
        <h3 style={styles.subHeader}>Integration with Immutable X</h3>
        <p style={styles.paragraph}>
          To enhance the NFT experience, Pillarwheel.io is tightly integrated with <strong>Immutable X</strong>. This layer-2 solution offers gas-free and instant transactions, providing a seamless and secure environment for minting and trading NFTs. With Immutable X, players retain full control of their assets, enjoying rapid and cost-effective interactions while maintaining Ethereum-level security.
        </p>
      </div>

      <div style={styles.section}>
        <h3 style={styles.subHeader}>Community-Driven DAO Governance</h3>
        <p style={styles.paragraph}>
          Our DAO governance model places the future of Pillarwheel in the hands of our community. Through the pillarwheel.io application, token holders can propose and vote on various aspects of game development and tokenomics. This democratic approach ensures that the evolution of our games is guided by the collective vision of our community members.
        </p>
      </div>

      <div style={styles.section}>
        <h3 style={styles.subHeader}>Proposals and Voting</h3>
        <p style={styles.paragraph}>
          Pillarwheel’s smart contracts enable the community to actively participate in directing the development of our RPG blockchain games. While developers manage the creative aspects, key decisions on the game's direction, such as system formations and environment designs, are determined by proposals and votes from our token holders. This collaborative process fosters innovation and ensures alignment with Pillarwheel's core principles.
        </p>
      </div>

      <p style={styles.finalParagraph}>
        With <strong>Pillarwheel.io</strong>, we’re not just developing games; we're creating a decentralized, player-driven platform where your voice matters and your gameplay has real-world impact.
      </p>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '800px',
    margin: 'auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    color: '#f5f5f5',
    backgroundColor: '#1b1b1b',
    borderRadius: '8px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.4)',
  },
  header: {
    fontSize: '2.5em',
    textAlign: 'center',
    color: '#f8f8f8',
    marginBottom: '20px',
  },
  section: {
    marginBottom: '40px',
    padding: '10px 20px',
    borderRadius: '5px',
    backgroundColor: '#292929',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
  },
  subHeader: {
    fontSize: '1.8em',
    color: '#e0e0e0',
    margin: '20px 0 10px',
  },
  paragraph: {
    fontSize: '1.15em',
    lineHeight: '1.8',
    color: '#dcdcdc',
    textAlign: 'justify',
  },
  finalParagraph: {
    fontSize: '1.2em',
    lineHeight: '1.6',
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#ffffff',
    marginTop: '30px',
  },
};

export default DAOs;
