import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, NavLink } from "react-router-dom";
import Web3 from "web3";
import './App.css';
import { Link, ImmutableXClient, ETHTokenType, ERC721TokenType } from '@imtbl/imx-sdk'; // Updated imports
import About from "./pages/About";
import Inventory from "./pages/Inventory";
import Games from "./pages/Games";
import DAOs from "./pages/DAOs";
import Merchandise from "./pages/Merchandise";
import RedirectHandler from "./components/RedirectHandler"; // Handle IMX redirects

const App = () => {
  const [account, setAccount] = useState(null);
  const [status, setStatus] = useState("Not connected");
  const [tier, setTier] = useState("Tier 1");
  const [walletAddress, setWalletAddress] = useState(null); // To store IMX wallet address
  const [theme, setTheme] = useState('light'); // Theme state

  // Initialize web3
  const web3 = new Web3(window.ethereum);

  // Initialize Immutable X Link
  const link = new Link('https://link.sandbox.x.immutable.com', {
    clientId: 'cixZTDwIA66HE6EFE3W6ZwcNbd1QLWYu', // Include your client ID here
  });

  // Connect Wallet (MetaMask)
  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const accounts = await web3.eth.getAccounts();
        setAccount(accounts[0]);
        setStatus(`Connected: ${accounts[0]}`);
      } catch (error) {
        setStatus("Failed to connect wallet");
        console.error(error);
      }
    } else {
      setStatus("MetaMask is not installed");
    }
  };

  useEffect(() => {
    // Check if wallet is already connected
    if (window.ethereum) {
      window.ethereum.request({ method: "eth_accounts" }).then((accounts) => {
        if (accounts.length > 0) {
          setAccount(accounts[0]);
          setStatus(`Connected: ${accounts[0]}`);
        }
      });
    }
  }, []);

  // Toggle Theme
  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
    // Additional logic to apply the theme can be added here
  };

  // Mint NFT
  const mintNFT = async () => {
    if (!account) {
      setStatus("Please connect wallet first");
      return;
    }

    let tokenMetadata, tokenPrice;
    if (tier === "Tier 1") {
      tokenMetadata = {
        name: "Pillarwheel NFT - Tier 1 (Gold)",
        description: "Exclusive access Tier 1 Gold content",
        image: "https://gateway.pinata.cloud/ipfs/QmUrWC6Ac9aig5tvaoFVXed16YFvFWQ74Ue6jZHMYJWNh9",
      };
      tokenPrice = Web3.utils.toWei("0.2", "ether");
    } else if (tier === "Tier 2") {
      tokenMetadata = {
        name: "Pillarwheel NFT - Tier 2 (Silver)",
        description: "Exclusive access Tier 2 Silver content",
        image: "https://gateway.pinata.cloud/ipfs/QmX7EYozexoyScBz5WTAfNfcjtWZE8WKHH1yVBKwpTjsM2",
      };
      tokenPrice = Web3.utils.toWei("0.1", "ether");
    } else if (tier === "Tier 3") {
      tokenMetadata = {
        name: "Pillarwheel NFT - Tier 3 (Bronze)",
        description: "Exclusive access Tier 3 Bronze content",
        image: "https://gateway.pinata.cloud/ipfs/QmdCqx73wZ5tfmikB969Rwfpf4cZEpyCBVHQe5yt22GED8",
      };
      tokenPrice = Web3.utils.toWei("0.05", "ether");
    }

    try {
      const client = await ImmutableXClient.build({
        publicApiUrl: "https://api.sandbox.x.immutable.com/v1",
        signer: web3.getSigner(), // Ensure signer is set
      });

      // Prepare mint data
      const mintData = {
        tokenType: ERC721TokenType,
        tokenId: `PillarwheelNFT-${tier}-${Date.now()}`, // Unique token ID
        tokenAddress: "0xa91c0945524a700eec9876a9f4a0a9ab44cded96",
        blueprint: JSON.stringify(tokenMetadata),
      };

      // Mint the NFT
      await client.mint({
        mints: [
          {
            etherKey: account.toLowerCase(),
            tokens: [mintData],
          },
        ],
      });

      setStatus("NFT minted successfully");
    } catch (error) {
      setStatus("Failed to mint NFT");
      console.error(error);
    }
  };

  // IMX Login
  const loginWithIMX = async () => {
    try {
      const response = await link.setup({});
      setWalletAddress(response.address);
      console.log('IMX Login successful:', response.address);
    } catch (error) {
      console.error("IMX Login failed:", error);
    }
  };

  
  // IMX Logout
  const logoutWithIMX = () => {
    setWalletAddress(null);
    console.log("User logged out");
  };


  return (
    <Router>
    <div className={`app ${theme}`}>
      <nav className="navbar">
        <div className="navbar-menu">
          <ul>
            {/* <li><button onClick={toggleTheme}>Toggle Theme</button></li> */}
            <li>{walletAddress ? <button onClick={logoutWithIMX}>Logout IMX</button> : <button onClick={loginWithIMX}>Login with IMX</button>}</li>
          </ul>
          <ul>
            <li><NavLink to="/" end className="nav-link">Shop</NavLink></li>
            <li><NavLink to="/inventory" className="nav-link">Inventory</NavLink></li>
            <li><NavLink to="/about" className="nav-link">About</NavLink></li>
            <li><NavLink to="/games" className="nav-link">Games</NavLink></li>
            <li><NavLink to="/daos" className="nav-link">DAOs</NavLink></li>
            <li><a href="https://medium.com/@pillarwheel" target="_blank" rel="noopener noreferrer" className="nav-link">News</a></li>
            <li><NavLink to="/merchandise" className="nav-link">Merchandise</NavLink></li>
          </ul>
        </div>
        <div className="wallet-connection">
          {account ? (
            <div>
              <span>Connected</span>
              <br />
              <small>{`${account.substring(0, 6)}...${account.substring(account.length - 4)}`}</small>
            </div>
          ) : (
            <button onClick={connectWallet}>Connect Wallet</button>
          )}
        </div>
      </nav>

      <div className="content">
        <Routes>
          <Route path="/" element={<Shop />} />
          <Route path="/about" element={<About />} />
          <Route path="/inventory" element={<Inventory account={account} />} />
          <Route path="/games" element={<Games />} />
          <Route path="/daos" element={<DAOs />} />
          <Route path="/merchandise" element={<Merchandise />} />
        </Routes>
      </div>
    </div>
  </Router>
  );
};

// Define the Shop page component with actual JSX
const Shop = ({
  connectWallet,
  account,
  status,
  tier,
  setTier,
  mintNFT,
  loginWithIMX,
  walletAddress,
}) => (
  <div className="app-container">
    <h1>Pillarwheel NFT Minting</h1>

    {/* Wallet Buttons */}
    <div className="wallet-buttons">
      {!account && <button onClick={connectWallet}>Connect Wallet</button>}
      {!walletAddress && <button onClick={loginWithIMX}>Login with IMX</button>}
    </div>

    <div className="select-container">
      <label htmlFor="tier-select">Select Tier: </label>
      <select id="tier-select" value={tier} onChange={(e) => setTier(e.target.value)}>
        <option value="Tier 1">Tier 1</option>
        <option value="Tier 2">Tier 2</option>
        <option value="Tier 3">Tier 3</option>
      </select>
    </div>
    {/* <button onClick={mintNFT} disabled={!account || !walletAddress}>
      Mint NFT
    </button> */}
    <p className="status-message">{status}</p>
  </div>
);

export default App;
