import React, { useState, useEffect, useCallback } from 'react';
import { Network, Alchemy } from 'alchemy-sdk';

const Inventory = ({ account }) => {
  const [nfts, setNfts] = useState([]);

  const fetchNFTs = useCallback(async () => {
    if (!account) return;

    const apiKey = process.env.REACT_APP_ALCHEMY_API_KEY;
    const settings = {
      apiKey: apiKey,
      network: Network.ETH_SEPOLIA,
    };
    const alchemy = new Alchemy(settings);

    try {
      const nftsForOwner = await alchemy.nft.getNftsForOwner(account);
      setNfts(nftsForOwner.ownedNfts);
    } catch (error) {
      console.error('Error fetching NFTs:', error);
    }
  }, [account]);

  useEffect(() => {
    if (account) {
      fetchNFTs();
    }
  }, [account, fetchNFTs]);

  const resolveIPFS = (url) => {
    if (!url) return 'carddefault.png'; // Local default image
    if (url.startsWith('ipfs://')) {
      return url.replace('ipfs://', 'https://gateway.pinata.cloud/ipfs/');
    } else if (url.startsWith('/ipfs/')) {
      return `https://gateway.pinata.cloud${url}`;
    }
    return url;
  };

  return (
    <div className="inventory-container">
      <h3>Your NFT Inventory on Sepolia</h3>
      {account ? (
        <div>
          <p>Connected Wallet: {account}</p>
          {nfts.length ? (
            <div className="nft-grid">
              {nfts.map((nft, index) => {
                const imageUrl = resolveIPFS(nft.media?.[0]?.gateway || nft.rawMetadata?.image);
                const title = nft.rawMetadata?.name || nft.title || 'Unnamed NFT';

                return (
                  <div key={index} className="nft-item">
                    <img
                      src={imageUrl}
                      alt={title}
                      onError={(e) => {
                        e.target.src = 'carddefault.png'; // Fallback image
                      }}
                    />
                    <p>{title}</p>
                  </div>
                );
              })}
            </div>
          ) : (
            <p>No NFTs found in this wallet on Sepolia.</p>
          )}
        </div>
      ) : (
        <p>Please connect your wallet to view your NFTs.</p>
      )}
    </div>
  );
};

export default Inventory;
