import React from 'react';


const About = () => {
  return (
    <div style={styles.container}>
      <h2 style={styles.header}>About</h2>
      <p style={styles.paragraph}>
        Welcome to <strong>Pillarwheel.io</strong>—your gateway to the future of gaming and blockchain technology. As the digital hub of Pillarwheel Studios, this platform connects you to our cutting-edge web3 applications, offering a new level of interaction and ownership within the gaming universe.
      </p>
      
      <div style={styles.section}>
        <img src="placeholder-for-pillarwheel.jpg" alt="Pillarwheel Logo" style={styles.image} />
        <h3 style={styles.subHeader}>What is Pillarwheel.io?</h3>
        <p style={styles.paragraph}>
          Pillarwheel.io is more than just a website; it's an immersive experience where gaming and blockchain technology converge. Dive into our play-to-earn games, where every move you make has the potential to earn real-world value.
        </p>
      </div>

      <div style={styles.section}>
        <img src="placeholder-for-web3.jpg" alt="Web3 Integration" style={styles.image} />
        <h3 style={styles.subHeader}>Web3 Integration</h3>
        <p style={styles.paragraph}>
          At the heart of Pillarwheel.io is our commitment to web3 integration, bringing decentralization, security, and true ownership to our community. Every action you take is secured and recorded on the blockchain, giving you full control over your digital belongings.
        </p>
      </div>

      <div style={styles.section}>
        <img src="placeholder-for-nft.jpg" alt="Mint NFTs" style={styles.image} />
        <h3 style={styles.subHeader}>Mint NFTs and Own Your Experience</h3>
        <p style={styles.paragraph}>
          Our NFT minting process allows you to create unique digital items that you can own, trade, or use across different games. This isn’t just about playing; it’s about building and owning a part of the game worlds you love.
        </p>
      </div>

      <div style={styles.section}>
        <img src="placeholder-for-play-to-earn.jpg" alt="Play-to-Earn Games" style={styles.image} />
        <h3 style={styles.subHeader}>Play-to-Earn Games</h3>
        <p style={styles.paragraph}>
          Pillarwheel.io is home to play-to-earn games where time and skill are rewarded with digital assets that have real-world value. Every victory has tangible value.
        </p>
      </div>

      <div style={styles.section}>
        <img src="placeholder-for-community.jpg" alt="Community" style={styles.image} />
        <h3 style={styles.subHeader}>Join the Pillarwheel Community</h3>
        <p style={styles.paragraph}>
          We’re building a vibrant community of gamers, creators, and blockchain enthusiasts. Joining our community grants you exclusive content and the chance to shape the future of Pillarwheel Studios.
        </p>
      </div>
      
      <p style={styles.finalParagraph}>
        Experience the future with Pillarwheel.io. Step into a world where gaming, blockchain, and ownership merge. At Pillarwheel.io, games are more than just entertainment—they’re a new way to play, earn, and own.
      </p>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '800px',
    margin: 'auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    color: '#f5f5f5',  // Light font color for dark background
    backgroundColor: '#1b1b1b',  // Dark background
    borderRadius: '8px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.4)',
  },
  header: {
    fontSize: '2.5em',
    textAlign: 'center',
    color: '#f8f8f8',
    marginBottom: '20px',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '40px',
  },
  subHeader: {
    fontSize: '1.8em',
    color: '#e0e0e0',
    margin: '20px 0',
  },
  paragraph: {
    fontSize: '1.15em',
    lineHeight: '1.8',
    color: '#dcdcdc', // Softer light color for readability
    textAlign: 'justify',
  },
  finalParagraph: {
    fontSize: '1.2em',
    lineHeight: '1.6',
    textAlign: 'center',
    fontWeight: 'bold',
    marginTop: '30px',
    color: '#ffffff',
  },
  image: {
    width: '100%',
    maxWidth: '500px',
    height: 'auto',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(255, 255, 255, 0.2)',
    marginBottom: '20px',
  },
};

export default About;
